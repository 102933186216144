import styles from "./RecordButton.module.css";
const RecordComfortButton = ({ event, label }) => {
  return (
    <button type="submit" className={styles.pushable} value={event}>
      <span className={styles.shadow}></span>
      <span className={`${styles.edge} ${styles[event]}`}></span>
      <span className={`${styles.front} ${styles[event]}`}>{label}</span>
    </button>
  );
};

export default RecordComfortButton;
