import { useRef } from "react";
import styles from "./SplashScreen.module.css";
import logo from "./img/MayMobilityHorizLogo.svg";
import off from "./img/offline.png";
import { useNavigate } from "react-router-dom";
const options = [
  { value: "matthew", label: "Matthew (demo)" },
  { value: "milo", label: "Milo (demo)" },
  { value: "madhav", label: "Madhav (demo)" },
  { value: "markov", label: "Markov (demo)" },
  { value: "morpheus", label: "Morpheus (demo)" },
  { value: "metheven", label: "Metheven (A2GO)" },
  { value: "makeba", label: "Makeba (A2GO)" },
  { value: "marinara", label: "Marinara (A2GO)" },
  { value: "mayble", label: "Mayble (A2GO)" },
  { value: "mitzi", label: "Mitzi (A2GO)" },
  { value: "momo", label: "Momo (A2GO)" },
];

export default function SplashScreen({ offline }) {
  const selectRef = useRef(null);
  const navigate = useNavigate();

  const handleContinue = () => {
    const mayid = selectRef.current.value;
    if (mayid === "") return null;
    navigate(`${mayid}/usertest`);
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.appName}>RideMayte</h1>
      <div className={styles.selectContainer}>
        <label htmlFor="may-id" className={styles.selectLabel}>
          Select a demo vehicle:
        </label>
        <select ref={selectRef} className={styles.selectInput} id="may-id">
          <option value="">Please select an option</option>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        <button className={styles.continueButton} onClick={handleContinue}>
          Continue
        </button>
      </div>

      {offline && (
        <div>
          <h3>Vehicle not in operation</h3>
          <img src={off} alt="offline-cartoon" />
        </div>
      )}
      <div>
        <h3>Powered by</h3>
        <img alt="may-mobility-logo" src={logo} />
      </div>
    </div>
  );
}
