import styles from "./LiveRideStatus.module.css";
import down from "./img/down.svg";
import up from "./img/up.svg";
import flat from "./img/flat.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Fragment, useRef } from "react";
const LIGHT_GREEN = "#BFE1C2";
const DARK_GREEN = "#3C8E45";
const YELLOW = "#F0BE0D";

const linearScale = (domain, range) => (value) => {
  const [istart, istop] = domain;
  const [ostart, ostop] = range;
  return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
};

const getStatusTextConfig = (lowerScore) => {
  let textColor;
  let rideStatusText;
  let arrow;
  textColor =
    lowerScore < 1 / 3 ? YELLOW : lowerScore < 2 / 3 ? LIGHT_GREEN : DARK_GREEN;
  rideStatusText =
    lowerScore < 1 / 3
      ? "COULD BE BETTER"
      : lowerScore < 2 / 3
      ? "AS EXPECTED"
      : "GREAT";
  arrow = lowerScore < 1 / 3 ? down : lowerScore < 2 / 3 ? flat : up;
  return { textColor, rideStatusText, arrow };
};

export default function LiveRideStatus({
  overallStatus,
  overallScore,
  comfortScore,
}) {
  const scaleSectionRef = useRef(null);
  const textRef = useRef(null);

  const loading =
    overallScore === undefined ||
    overallScore === null ||
    overallStatus === null ||
    overallStatus === undefined;

  const scalePointerScale = linearScale([0, 1], [0, 300]);

  const lowerOfTwoScores = Math.min(overallScore, comfortScore);
  const { textColor, rideStatusText, arrow } =
    getStatusTextConfig(lowerOfTwoScores);
  return (
    <div>
      <div ref={textRef} className={styles.overallStatus}>
        {loading ? (
          <div className={styles.overallStatusIcon}>
            <Skeleton
              circle
              width="50px"
              height="50px"
              containerClassName="avatar-skeleton"
            />
          </div>
        ) : (
          <div
            className={styles.overallStatusIcon}
            style={{ backgroundColor: textColor }}
          >
            <img src={arrow} alt="overall-status" />
          </div>
        )}
        {loading ? (
          <div className={styles.overallStatusText}>
            <Skeleton width="200px" />
          </div>
        ) : (
          <div className={styles.overallStatusText}>
            <p>
              Your ride's overall self-driving performance{" "}
              {rideStatusText !== "COULD BE BETTER" && "has been "}
              <span
                style={{
                  color: textColor,
                  fontWeight: 800,
                }}
              >
                {rideStatusText}
              </span>{" "}
              compared to similar May rides
            </p>
          </div>
        )}
      </div>
      <div className={styles.scaleSection} ref={scaleSectionRef}>
        <div
          className={`${styles.scaleContainer} ${
            loading ? styles.loading : ""
          }`}
        >
          {loading ? (
            <Skeleton count={3} />
          ) : (
            <Fragment>
              <div
                className={styles.pointerContainer}
                style={{
                  left: `${scalePointerScale(overallScore)}px`,
                }}
              >
                <p className={styles.pointerLabel}>performance</p>
                <div className={styles.pointer}></div>
              </div>

              <div
                className={styles.pointerContainer}
                style={{
                  left: `${scalePointerScale(comfortScore)}px`,
                }}
              >
                <div className={`${styles.pointer} ${styles.comfort}`}></div>
                <p className={`${styles.pointerLabel} ${styles.comfort}`}>
                  comfort
                </p>
              </div>

              <div className={styles.currentStatusScale}>
                <div className={styles.blockContainer}>
                  <div
                    className={`${styles.colorBlock} ${styles.left}`}
                    style={{ backgroundColor: YELLOW }}
                  ></div>
                  <p>Could be Better</p>
                </div>
                <div className={styles.blockContainer}>
                  <div
                    className={`${styles.colorBlock}`}
                    style={{ backgroundColor: LIGHT_GREEN }}
                  ></div>
                  <p>As Expected</p>
                </div>
                <div className={styles.blockContainer}>
                  <div
                    className={`${styles.colorBlock} ${styles.right}`}
                    style={{ backgroundColor: DARK_GREEN }}
                  ></div>
                  <p>Great</p>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <div className={styles.explanationText}>
          Weather, road conditions and construction are all factors that impact
          our vehicle’s self-driving performance
        </div>
      </div>
    </div>
  );
}
