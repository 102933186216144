import styles from "./LiveMap.module.css";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Layer, Source, NavigationControl } from "react-map-gl";
import { useEffect, useRef, useState } from "react";
import deepEqual from "deep-equal";

export default function LiveMap({
  showODSLayer,
  carPos,
  segmentColors,
  idle,
  id,
}) {
  const { lat, lng } = carPos;
  const mapRef = useRef();
  const [colorMap] = useState({});
  const [colorArr, setColorArr] = useState([]);
  useEffect(() => {
    mapRef.current?.flyTo({ center: [lng, lat], duration: 1000 });
  }, [lat, lng]);

  useEffect(() => {
    if (
      segmentColors !== undefined &&
      segmentColors !== null &&
      Object.keys(segmentColors).length > 0 &&
      !deepEqual(colorMap, segmentColors)
    ) {
      let colorArr = [];
      for (const [k, v] of Object.entries(segmentColors)) {
        colorArr.push(Number(k), v);
      }
      setColorArr(colorArr);
    }
  }, [colorMap, segmentColors]);

  return (
    <div className={`${styles.mapContainer} ${idle ? styles.idle : ""}`}>
      <Map
        id={id}
        ref={mapRef}
        initialViewState={{
          longitude: lng || -83.743,
          latitude: lat || 42.2742,
          zoom: 15,
        }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/sdi-mapbox/ckljmydtx12aj17p7nwxws7ct"
      >
        <NavigationControl />
        {renderCarLayer(carPos)}
        {renderSegmentLayer(colorArr, showODSLayer)}
      </Map>
    </div>
  );
}

function renderCarLayer({ lat, lng, heading }) {
  if (lat !== undefined && lng !== undefined) {
    return (
      <Source
        type="geojson"
        data={{
          type: "Point",
          coordinates: [lng, lat],
        }}
      >
        <Layer
          id="live-car-location"
          type="symbol"
          layout={{
            "icon-image": "may-car",
            "icon-rotate": heading ? heading : 0,
          }}
        />
      </Source>
    );
  }
}

function renderSegmentLayer(colorArr, showODSLayer) {
  return (
    <Source
      type="vector"
      url={"mapbox://sdi-mapbox.clewp86ue01gz2ko1pb1laxkd-2swgl"}
    >
      <Layer
        source-layer="ARB_ODS_demo_segments"
        beforeId="live-car-location"
        id="live-segment"
        type="line"
        paint={generatePaintProp(colorArr, showODSLayer)}
      />
    </Source>
  );
}

function generatePaintProp(colorArr, showODSLayer) {
  if (colorArr.length === 0) {
    return {
      "line-width": 5,
      "line-color": showODSLayer ? "silver" : "transparent",
    };
  }
  return {
    "line-width": 5,
    "line-color": [
      "match",
      ["get", "segment_id"],
      ...colorArr,
      showODSLayer ? "silver" : "transparent",
    ],
  };
}
