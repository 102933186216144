import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import SplashScreen from "./SplashScreen";
import LiveRideScreen from "./LiveRideScreen";
const router = createBrowserRouter([
  {
    path: "/:mayId/debug",
    element: <LiveRideScreen debug={true} />,
  },
  {
    path: "/:mayId",
    element: <LiveRideScreen />,
  },
  {
    path: "/:mayId/demo",
    element: <LiveRideScreen demo={true} />,
  },
  {
    path: "/:mayId/usertest",
    element: <LiveRideScreen usertest={true} />,
  },
  {
    path: "/",
    element: <SplashScreen />,
    errorElement: <ErrorPage />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
