import { useParams } from "react-router-dom";
import styles from "./Header.module.css";
import Switch from "react-switch";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import toast, { Toaster } from "react-hot-toast";
import RecordButton from "./RecordButton";
export default function Header({
  socket,
  showODSLayer,
  onToggle,
  debug,
  demo,
  usertest,
  hasActiveDemo,
  segmentStatus,
}) {
  const { mayId } = useParams();
  const [isDemoOn, setDemoOn] = useState(hasActiveDemo);
  const [isClear, setClear] = useState(false);

  const handleToggle = () => {
    onToggle(!showODSLayer);
  };
  const handleDemoToggle = () => {
    const previousDemoState = isDemoOn;
    setDemoOn(!previousDemoState);

    if (previousDemoState === undefined || previousDemoState === false) {
      socket.emit("start_ride", "start");
    } else {
      socket.emit("end_ride", "end");
    }
    socket.addEventListener("error", (event) => {
      setDemoOn(previousDemoState);
    });
  };
  const handleClearMap = () => {
    setClear(!isClear);
  };

  const sendMsg = useDebouncedCallback(
    (e) => {
      const form = e.target;
      const formData = new FormData(form);
      const formValues = Object.fromEntries(formData.entries());
      const event = e.nativeEvent.submitter.value;
      toast.success("Message sent!", {
        duration: 1000,
      });
      const msg = {
        event,
        user: formValues.name,
      };
      socket.emit("event", JSON.stringify(msg));
      socket.addEventListener("error", () => {
        toast.error("Failed to send msg", { duration: 1000 });
      });
    },
    3000,
    {
      leading: true,
      trailing: false,
    }
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const noActiveRides = Object.keys(segmentStatus).length === 0;
    if (noActiveRides) {
      toast.error("No active rides", {
        duration: 1000,
      });
    } else {
      sendMsg(e);
    }
  };
  useEffect(() => {
    if (socket !== null && isClear) {
      socket.emit("clear_map", "clear");
    }
  }, [isClear, socket]);

  useEffect(() => {
    setDemoOn(hasActiveDemo);
  }, [hasActiveDemo]);

  return (
    <div className={styles.header}>
      <h1 className={styles.vehicleName}>{mayId}</h1>
      {usertest && (
        <form className={styles.form} method="post" onSubmit={handleSubmit}>
          <Toaster position="top-right" />
          <input
            name="name"
            id="name"
            required
            placeholder="your first name"
            size="15"
          />
          <div className={styles.submitButtons}>
            <RecordButton event="aggressive" label="Hard Braking" />
            <RecordButton event="hesitation" label="Hesitation" />
            <RecordButton event="swimming" label="Swimming" />
            <RecordButton event="other" label="Other Discomfort" />
          </div>
        </form>
      )}

      <div className={styles.controls}>
        {!usertest && (
          <label>
            <span>Display Service Route</span>
            <Switch
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              onChange={handleToggle}
              checked={showODSLayer}
              className={styles.reactSwitch}
            />
          </label>
        )}

        {(debug === true || demo === true) && (
          <label>
            <span>Start Demo</span>
            <Switch
              disabled={isClear}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              onChange={handleDemoToggle}
              checked={isDemoOn}
              className={styles.reactSwitch}
            />
          </label>
        )}
        {debug === true && (
          <label>
            <span>Clear Map</span>
            <Switch
              disabled={isDemoOn}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              onChange={handleClearMap}
              checked={isClear}
              className={styles.reactSwitch}
            />
          </label>
        )}
        {/* {usertest === true && (
            <form method="post" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Your first name:</label>
                <input name="name" id="name" required />
              </div>

              <div>
                <input
                  type="radio"
                  id="choice1"
                  name="comfort"
                  value="record_aggressive_event"
                  required
                />
                <label htmlFor="choice1">Uncomfortable</label>
                <input
                  type="radio"
                  id="choice2"
                  name="comfort"
                  value="record_extremely_aggressive_event"
                  required
                />
                <label htmlFor="choice2">Extremely Uncomfortable</label>
              </div>
              <button type="submit">Submit</button>
            </form>
          )} */}
      </div>
    </div>
  );
}
