import Header from "./Header";
import LiveMap from "./LiveMap";
import LiveRideStatus from "./LiveRideStatus";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./LiveRideScreen.module.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import deepEqual from "deep-equal";
import io from "socket.io-client";

export default function LiveRideScreen({ debug, demo, usertest }) {
  const { mayId } = useParams();
  const ws = useRef(null);
  const [carPos, setCarPos] = useState({
    lat: undefined,
    lng: undefined,
    heading: undefined,
  });
  const [segmentStatus, setSegmentStatus] = useState({});
  const [showODSLayer, setShowODSLayer] = useState(true);
  const [currSegment, setCurrSegment] = useState(undefined);
  const [hasActiveDemo, setHasActiveDemo] = useState(undefined);

  useEffect(() => {
    const socket = io(
      `https://ridemayte-server.app-sandbox.maymobility.com/${mayId}`
    );
    ws.current = socket;
    socket.onAny((e, msg) => {
      const rideMsg = JSON.parse(msg)[mayId];
      const { lat, lng, active_rides, heading, segment_id, has_active_demo } =
        rideMsg;
      if (!deepEqual(carPos, { lat, lng, heading })) {
        setCarPos({ lat, lng, heading });
      }
      if (segment_id !== currSegment) setCurrSegment(segment_id);
      if (has_active_demo !== hasActiveDemo) setHasActiveDemo(has_active_demo);
      if (active_rides.length > 0) {
        let newSegStatus = { ...segmentStatus };
        for (const ride of active_rides) {
          const { ride_id } = ride;
          if (!deepEqual(ride, segmentStatus[ride_id])) {
            newSegStatus[ride_id] = ride;
          }
        }
        setSegmentStatus(newSegStatus);
      } else {
        setSegmentStatus({});
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  if (!carPos.lat || !carPos.lng)
    return (
      <div className={styles.loadingContainer}>
        <p>loading your vehicle info...</p>
        <p>If you're stuck here the vehicle is probably not in operation</p>
      </div>
    );
  return (
    <div className={styles.liveScreenContainer}>
      <Header
        demo={demo}
        debug={debug}
        usertest={usertest}
        socket={ws.current}
        showODSLayer={showODSLayer}
        onToggle={setShowODSLayer}
        hasActiveDemo={hasActiveDemo}
        segmentStatus={segmentStatus}
      />

      <Tabs className={styles.tabContainer}>
        {renderTabs(segmentStatus, currSegment, { debug, usertest })}
        {renderTabPanel(carPos, segmentStatus, showODSLayer)}
      </Tabs>
    </div>
  );
}

function renderTabs(segmentStatus, currSegment, { debug, usertest }) {
  return (
    <TabList className={styles.tablistContainer}>
      {Object.keys(segmentStatus).length === 0 ? (
        <Tab>Live</Tab>
      ) : (
        Object.keys(segmentStatus).map((id) => {
          const {
            event,
            ride_score,
            segment_scores,
            count_decelerations_aggressive,
            count_decelerations_ext_aggressive,
          } = segmentStatus[id];
          let currSegScore = "NA";
          let overallScore =
            ride_score !== undefined && ride_score !== null
              ? ride_score.toFixed(2)
              : "NA";
          if (
            segment_scores !== null &&
            segment_scores[currSegment] !== undefined &&
            segment_scores[currSegment] !== null
          ) {
            currSegScore = segment_scores[currSegment];
          }
          const sensorComfortReading = `${count_decelerations_aggressive}A ${count_decelerations_ext_aggressive}E`;
          const usertestTag =
            event !== "Completed"
              ? `(${event} | ${currSegment} | ${sensorComfortReading} )`
              : `(${event} | ${sensorComfortReading})`;

          const debugTag =
            event !== "Completed"
              ? `(${event} | ${overallScore} | ${currSegment} : ${
                  currSegScore === "NA" ? currSegScore : currSegScore.toFixed(2)
                })`
              : `(${event} | ${overallScore})`;
          const normalTag = event === "Completed" && event;

          const renderTag = (debug, usertest) => {
            if (debug) return debugTag;
            if (usertest) return usertestTag;
            return normalTag;
          };
          return (
            <Tab key={id}>
              Ride #{id} {renderTag(debug, usertest)}
            </Tab>
          );
        })
      )}
    </TabList>
  );
}

function renderTabPanel(carPos, segmentStatus, showODSLayer) {
  if (Object.keys(segmentStatus).length === 0) {
    return (
      <TabPanel selectedClassName={"selected-panel"}>
        <LiveMap
          id="idleMap"
          showODSLayer={showODSLayer}
          carPos={carPos}
          idle={true}
        />
      </TabPanel>
    );
  }
  return Object.keys(segmentStatus).map((id, i) => {
    return (
      <TabPanel key={id} id={id} selectedClassName={"selected-panel"}>
        <LiveMap
          id={id}
          showODSLayer={showODSLayer}
          carPos={carPos}
          segmentColors={segmentStatus[id].segment_colors}
        />
        <LiveRideStatus
          comfortScore={segmentStatus[id].comfort_score}
          overallStatus={segmentStatus[id].ride_color}
          overallScore={segmentStatus[id].ride_score}
        />
      </TabPanel>
    );
  });
}
